$footer-height: 40px;

$app-menu-dekstop-width: 210px !default;
$app-menu-mobile-width: 200px !default; // should be different
$app-menu-background-color: #003673;

$contractors-list-height: 32px;
$contractors-list-height-phone: 24px;
$contractors-list-color: #2f2f2f;

$panel-contractors-border: rgba(0, 0, 0, 0);
$panel-contractors-text: #fff;
$panel-contractors-heading-bg: #004ba2;
$panel-contractors-heading-height: 40px;

$view-nav-height: 40px;
$ive-logo-height: 56px;

$contractor-more-padding: 100px;
$contractor-more-padding-mobile: 50px;