﻿// Biryani supported font sizes
$biryani-light: 300;
$biryani-regular: 400;
$biryani-extra-bold: 800;
$biryani-heavy: 900;

// Bootstrap overrides

$font-size-base: 14px;
$font-size-h1: 22px;
$font-size-h2: 22px;
$font-size-h3: 22px;
$headings-color: #004ba2;
$accounts-color: #004ca2;
$accounts-font-size: 12px;

$grid-float-breakpoint: 980px;

$navbar-height: 100px;
$navbar-border-radius: 0;
$navbar-default-color: #004ba2;
$navbar-default-bg: transparent;
$navbar-default-border: transparent;
$navbar-default-link-color: #004ba2;
$navbar-default-link-hover-color: $navbar-default-link-color;
$navbar-default-link-active-color: $navbar-default-link-color;

$panel-border-radius: 8px;

/* Do not remove this comments bellow. It's the markers used by wiredep to inject
   sass dependencies when defined in the bower.json of your dependencies */
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

// Other imports
@import "_variables";
@import "_icons";

html {
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
    background: url("/Graphics/background-desktop.jpg") no-repeat bottom center / cover;
    background-attachment: fixed;
    padding-bottom: $footer-height;

    // Compensate for the left app-menu
    @media (min-width: $screen-sm-min) {
        padding-left: $app-menu-mobile-width;
    }

    @media(min-width: $screen-md-min) {
        padding-left: $app-menu-dekstop-width;
    }
}

h1, h2, h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: $line-height-computed;
}

.body-content {
    margin: 0 auto;
    padding: 0 $grid-gutter-width / 2;

    @media (min-width: $screen-md-min) {
        max-width: $container-sm;
    }

    @media (min-width: $screen-md-min) {
        max-width: $container-md;
    }

    @media (min-width: $screen-md-min) {
        max-width: $container-lg;
    }
}

.app-view {
    padding-bottom: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);
}

.block {
    display: block;
}

input.ng-invalid,
textarea.ng-invalid,
select.ng-invalid {
    border-color: #a94442 !important;
    box-shadow: 0 0 6px #F8B9B7 !important;
}

footer {
    font-weight: $biryani-light;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    height: $footer-height;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-left: inherit; // use body padding

    span {
        display: block;
    }

    @media (min-width: $screen-md-min) {
        font-weight: $biryani-regular;
        font-size: 12px;
        padding-top: 10px;

        span {
            display: inline-block;
        }
    }
}

.app-header {
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-sm-min) {
        max-width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        max-width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        max-width: $container-lg;
    }

    .navbar-brand {
        line-height: $navbar-height;
        padding-top: 0;
        padding-bottom: 0;

        img {
            display: inline-block;
        }
    }

    .navbar-toggle {
        padding: 0;
        border-radius: 0;
        line-height: 1;
        border-color: $navbar-default-border;

        @include navbar-vertical-align(27px);
    }

    .toggle-icon {
        @extend .icon;
        @extend .icon-menu;
    }
}
.login-header {
    font-size: 13px;
    margin-left: $app-menu-dekstop-width;
    margin-right: auto;

    @media (min-width: $screen-sm-min) {
        max-width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        max-width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        max-width: $container-lg;
    }

    .navbar-brand {
        line-height: $navbar-height;
        padding-top: 0;
        padding-bottom: 0;

        img {
            display: inline-block;
        }
    }

    .navbar-toggle {
        padding: 0;
        border-radius: 0;
        line-height: 1;
        border-color: $navbar-default-border;

        @include navbar-vertical-align(27px);
    }

    .toggle-icon {
        @extend .icon;
        @extend .icon-menu;
    }
}

.app-menu {
    background-color: rgba($app-menu-background-color, .4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index:1000;

    .menu-header {
        height: 160px;
        background-color: $app-menu-background-color;

        .menu-title {
            font-size: 22px;
            font-weight: $biryani-regular;
            color: #7da7d1;
            text-transform: uppercase;
            padding-top: 110px;
            padding-left: 38px;
        }
    }

    .menu-body {
        list-style: none;
        padding: 0;

        li {
            height: 56px;
            line-height: 56px;
        }

        a {
            color: #fff;
            padding-left: 38px;
            border-bottom: 1px solid #8AA6CF;
            display: block;
        }
    }

    width: $app-menu-mobile-width;
    display: none;

    @media (min-width: $screen-sm-min) {
        display: block;        
        z-index:1000;
    }

    @media(min-width: $screen-md-min) {
        width: $app-menu-dekstop-width;        
        z-index:1000;
    }
}
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
// endinjector
