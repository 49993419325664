.icon {
    display:inline-block; 
    overflow:hidden; 
    background-repeat: no-repeat;
    background-image:url(../assets/images/icons.png);
    vertical-align: middle;
}

.icon-add {width:20px; height:20px; background-position: -40px -58px}
.icon-collapse {width:33px; height:33px; background-position: -2px -2px}
.icon-delete {width:13px; height:21px; background-position: -73px -2px}
.icon-expand {width:33px; height:33px; background-position: -2px -37px}
.icon-menu {width:31px; height:27px; background-position: -40px -2px}
.icon-search {width:19px; height:19px; background-position: -62px -58px}
.icon-unverified {width:17px; height:18px; background-position: -2px -72px}
.icon-unverified-circle {width:25px; height:25px; background-position: -37px -31px}
.icon-verified {width:17px; height:18px; background-position: -21px -72px}
.icon-verified-circle {width:25px; height:25px; background-position: -64px -31px}

